<template>
  <page-loader v-if="loading" />
  <div v-else class="w-100">
    <div class="max-w-840 th-mx-auto">
      <h6 class="caption th-mb-8 primary--text">
        {{ $translation.t("Data usage") }}
      </h6>
      <h5 v-html="name" />
      <product-step-wrapper
        :questions="questions"
        :default-model="model"
        :submit-function="saveData"
        :on-success="onSubmit"
      >
        <template v-slot:title>
          {{ $t("product.step.data_usage_purpose.title") }}
        </template>
        <template v-slot:subtitle>
          {{ $t("product.step.data_usage_purpose.description") }}
        </template>
      </product-step-wrapper>
    </div>
  </div>
</template>

<script>
import ProductStepWrapper from "../../../../components/Questionnaire/ProductSteps/ProductStepWrapper";
import defaultModel from "../../../../data/questionnaire/business/businessDataUsagePurpose";
import rules from "../../../../utils/inputRules";
import PageLoader from "../../../../components/PageLoader";
import { questionsBySubtype } from "../../../../utils/service/Questionnaire/data_usage_purpose";
import { dataUsagePurposes } from "../../../../utils/service/Questionnaire/index";

const QUESTIONS_TYPE = "data_usage_purpose";

export default {
  name: "DataUsagePurpose",
  components: { PageLoader, ProductStepWrapper },
  props: {
    onSubmit: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      rules,
      // questions: questionsBySubtype(QUESTIONS_TYPE, this.$route.params.locale),
      model: { ...defaultModel },
      showName: true,
      loading: true,
    };
  },
  beforeRouteEnter(to, from, next) {
    if (!to.params.uuid && !to.params.data_usage_purpose_slug) {
      next({
        name: "public_questionnaire_data_usage_purpose",
        params: { ...to.params },
      });
    }

    next();
  },
  async created() {
    try {
      if (this.$route.params.uuid) {
        this.model =
          (
            await this.$axios.get(`/api/v1/business/data/${QUESTIONS_TYPE}`, {
              params: { uuid: this.$route.params.uuid },
            })
          )?.params || defaultModel;
      }
    } catch {
      // Do nothing
    } finally {
      this.loading = false;
    }

    this.$route.params.type = QUESTIONS_TYPE;

    this.model.data_usage_purpose_name =
      this.$route.params.data_usage_purpose_name ||
      this.model.data_usage_purpose_name;

    this.model.slug =
      this.$route.params.data_usage_purpose_slug || this.model.slug;

    if (this.model.data_usage_purpose_name) {
      this.showName = false;
    }
  },
  computed: {
    name() {
      return (
        dataUsagePurposes.find(({ slug }) => slug === this.slug)?.name ||
        this.model["data_usage_purpose_name"]
      );
    },
    slug() {
      return (
        this.model["slug"] ||
        dataUsagePurposes.find(
          ({ name }) => name === this.model["data_usage_purpose_name"]
        )?.slug
      );
    },
    questions() {
      return questionsBySubtype(this.slug);
    },
  },
  methods: {
    async saveData(model) {
      try {
        this.$event(`onboarding.${QUESTIONS_TYPE}.submitted`, {
          locale: this.$route.params?.locale || "en",
          uuid: this.$route.params.uuid,
        });

        // Add name if does not exist
        model.data_usage_purpose_name =
          model.data_usage_purpose_name || this.name;

        return await this.$axios.post(`/api/v1/business/data`, {
          locale: this.$route.params?.locale || "en",
          type: QUESTIONS_TYPE,
          uuid: this.$route.params.uuid,
          params: model,
        });
      } catch (e) {
        this.$log.notify(e);
      }
    },
    next() {
      if (this.$refs.dataUsagePurposeNameForm?.validate())
        this.showName = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
