import questions_lt_v1 from "./lt/questions.json";
import questions_marketing_lt_v1 from "./lt/questions_marketing.json";
import questions_statistics_lt_v1 from "./lt/questions_statistics.json";
import questions_law_lt_v1 from "./lt/questions_law.json";
import questions_contract_lt_v1 from "./lt/questions_contract.json";

export const LATEST_VERSION = "v1";

export const questionsBySubtype = (subtype = "") => {
  switch (subtype) {
    case "law_purpose":
      return questions_law_lt_v1;
    case "contract_purpose":
      return questions_contract_lt_v1;
    case "agree_purpose":
      return questions_marketing_lt_v1;
    case "statistics_purpose":
      return questions_statistics_lt_v1;
    default:
      return questions_lt_v1;
  }
};

export const questions = (version = LATEST_VERSION) => {
  switch (version) {
    case "v1":
    default:
      return questions_lt_v1;
  }
};
