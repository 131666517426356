let data = {};

// if (process.env.NODE_ENV === "development")
//   data = {
//     data_usage_purpose_name: "Duomenu tvarkymo tikslas 4",
//     data_use_whos_data: ["kandidatai į laisvas darbo vietas"],
//     data_use_what_data: [
//       "informacija apie išsilavinimą",
//       "IDV pažyma, verslo liudijimas",
//     ],
//     data_use_what_data_degree: [
//       "mokslo (aukštojo/vidurinio/profesinio) baigimo diplomas",
//     ],
//     data_use_data_storage: [
//       "buhalterinės apskaitos sistema",
//       "įmonei priklausančiame kompiuteryje/serveryje, esančiame įmonės patalpose",
//     ],
//     data_use_data_storage_remote_server: [],
//     data_use_data_storage_crm: [],
//     data_use_data_accounting_system: ["Rivilė"],
//     data_use_data_from: [
//       "duomenų subjekto tiesiogiai (raštu arba žodžiu, el paštu)",
//     ],
//     data_use_data_passed_to_third_party: "true",
//     data_use_third_party_in_eu: ["DARBO BIRŽA"],
//     data_use_third_party_in_eu_use_data: ["SODRA"],
//     data_use_third_party_outside_eu_exists: "false",
//     data_use_third_party_outside_eu: [],
//     data_use_third_party_outside_eu_use_data: [],
//     data_use_third_party_receive: ["Elektroniniu formatu"],
//     data_use_role_with_access_to_data: [
//       "direktorius",
//       "IT administratorius",
//       "buhalterija",
//       "personalo skyriaus darbuotojai",
//     ],
//     data_use_role_with_access_to_data_services: [],
//     data_use_store_data_period_in_months: "12",
//     data_use_store_data_period_defined_in_documents_exists: "true",
//     data_use_store_data_period_defined_in_documents: ["tvarka1"],
//   };

export default data;
